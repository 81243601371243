require(['componentLoader'], function(helper) {
  helper.requireDependencies();
});

define(['common'], function() {

  var purinadirect = function() {
    var self = this;
    function init() {
    }

    self.init = init;
  };

  var purinadirectSite = new purinadirect();
  purinadirectSite.init();
  return purinadirectSite;
});
